export function getDummyTopics() {
    let dummytopic = (
      <div className="threadRowDiv lazyLoadingEffect">
        <table className="threadRow"><tbody>
          <tr className="threadRow">
            <td className="threadCell threadPinCell"> &nbsp; <br/> &nbsp; </td>
            <td className="threadCell threadTitleCell"><b> </b></td>
            <td className="threadCell threadDescCell"> </td>
            
            <td className="threadCell threadUserCell"><span> </span></td>
            <td className="threadCell threadActCell"> </td>
            <td className="threadCell threadCreateCell"> </td>
          </tr>
        </tbody></table>
      </div>
    );
  
    let table = (
      <div>
        <div>
        <table className="threadTable">
          <thead>
            <tr>
              <th className="threadCell threadPinCell"></th>
              <th className="threadCell threadTitleCell">thread Title</th>
              <th className="threadCell threadDescCell">thread Description</th>
              <th className="threadCell threadUsereCell">Creator</th>
              <th className="threadCell threadActCell">Last Activity</th>
              <th className="threadCell threadCreateCell">Date Created</th>
            </tr>
          </thead>
        </table>
        </div>
        {dummytopic}{dummytopic}{dummytopic}
        {dummytopic}{dummytopic}{dummytopic}
        {dummytopic}{dummytopic}{dummytopic}
      </div>    
    );
    return table;
  }