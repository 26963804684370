import Resizer from "react-image-file-resizer";

const resizeFile = (file) => new Promise(resolve => { //https://stackoverflow.com/questions/61740953/reactjs-resize-image-before-upload
    Resizer.imageFileResizer(file, 1000, 1000, 'PNG', 100, 0,
    uri => {
      resolve(uri);
    }, 'base64' );
  });

function dataURItoBlob(dataURI) { //https://stackoverflow.com/questions/9388412/data-uri-to-object-url-with-createobjecturl-in-chrome-ff
    var mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {type: mime});
}

export default {resizeFile, dataURItoBlob};