import React, { useState, useEffect } from 'react';
import '../../App.css';
import '../../styles/threadViewer.css';
import '../../styles/tooltips.css';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  Redirect,
  useLocation,
  renderMatches,
  useParams
} from "react-router-dom";
import { getDummyTopics as getDummyThreads } from './DummyTopics';
import TopicManager from './TopicManager';
import UserProfileDisplay from '../Account/UserProfileDisplay';

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];


function getThreadTable(data, user) {
  let tbody = data.map(row => {
    let act = new Date(row['last_activity']);
    let created = new Date(row['date_created']);
    if(!row.deleted || (row.username === user?.username) || (user?.administrator === 1)){
      let deletedMsg = (
        row.deleted === 1 ? <label style={{"color": "red"}}><i>Deleted</i></label> : ""
      );
      let body;
      if(row.description.length > 65)
        body = row.description.substring(0, 300) + " ...";
      else
        body = row.description
      let img = (
        row['imageURL'] && row['imageURL'] !== "No Image" 
        ? 
        <img loading="lazy" className="image" src={"https://api.board.dylang140.com/" + row['imageURL']}/>
        : ""
      );
      
      return(
        <Link className="ThreadRowLink" to={`/post/` + row['ID']}>
          <div className="threadRowDiv" style={{backgroundColor: row['deleted'] ? '#d2abab' : ''}}>
            <div className="threadCell">
              <table><tr>
                <td>{row['pinned'] === 1 ? "📌" : ""}</td>
                <td><UserProfileDisplay comment={row}/></td>
              </tr></table>
              <div className="threadCell">
                <h2>{row['title']}</h2>
                <p>{body}</p>
                {img}
              </div>
              <div className="threadCell">
                <label>{row['NumComments']} 💬 </label>
                {months[act.getMonth()] + " " + act.getDate() + ", " + act.getFullYear()}    
              </div>
            </div>
          </div>
        </Link>
      );
    }
  });
  let table = (
    <div>
      <div>
      
      </div>
      {tbody}
    </div>    
  );
  return table;
}

export default function Home({ sessionData }) {
  let [threads, setThreads] = useState();
  let [threadsPage, setThreadsPage] = useState(0);
  let [countThreads, setCountThreads] = useState(60);
  let [load, setLoad] = useState(true);
  let [hasNextPage, setHasNextPage] = useState(true);
  let [hasPrevPage, setHasPrevPage] = useState(false);
  let [topicData, setTopicData] = useState();
  const { topicID } = useParams();

  const threadsPerPage = 10;

  useEffect(() => {
    if(!threads || load == true){
      setLoad(false);
      console.log("TOPIC " + topicID);
      fetch("https://api.board.dylang140.com/threadsPage", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({requestDeleted: sessionData?.user?.administrator, username: sessionData?.user?.username, requestNum: threadsPerPage, requestStart: threadsPage*threadsPerPage, topic: topicID})
      })
        .then((res) => res.json())
        .then((data) => {
          setCountThreads(data.threadsCount);
          setThreads(data.threads);
          setTopicData(data.topic[0]);
        });
    }
  });

  const nextPage = () => {
    //let newCount = (countThreads / 20) - 1 > threadsPage ? threadsPage + 1 : threadsPage;
    if((countThreads / threadsPerPage) - 1 > threadsPage) {
      //console.log(threadsPage + " "  + " " + load);
      setThreadsPage(threadsPage + 1);
      setLoad(true);
      setHasPrevPage(true);
    }
    else {
      setHasNextPage(false);
    }
  }
  const prevPage = () => {
    let newCount = threadsPage > 0 ? threadsPage - 1 : 0;
    //console.log(threadsPage + " " + newCount + " " + load);
    if(newCount != threadsPage) {
      setThreadsPage(newCount);
      setLoad(true);
      setHasNextPage(true);
    }
    else {
      setHasPrevPage(false);
    }
  }

  let nextButton = (<div>Next<input type="image" src="../nextArrow.png" height="30px" onClick={() => nextPage()}style={{verticalAlign: 'middle'}} /></div>);
  let prevButton = (<div><input type="image" src="../prevArrow.png" height="30px" onClick={() => prevPage()} style={{verticalAlign: 'middle'}}/>Previous</div>);
  let dumbButton = (<button className="Button">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>);
  let manager = (topicData ? <TopicManager sessionData={sessionData} topicData={topicData} /> : <div></div>)
  let buttons = (
    <div style={{margin: 'auto', width: '50%', padding: '10px', textAlign: 'center'}}>
      {hasPrevPage ? prevButton : dumbButton}
      &nbsp; Page {threadsPage + 1} &nbsp;
      {hasNextPage ? nextButton : dumbButton}
    </div>
  );

  return (
    <div className="Page">
      
      <table style={{width: '100%'}}><tbody>
        <tr key={"space"}>
          <td>
            <h1>Topic: {topicData ? topicData.title : ""}</h1>
            <br/>
            {manager}
            <br/>
          </td>
          <td>
            <div className='tooltip' style={{float: 'right'}}>
              <span className='tooltipcontents'>
                New Post
              </span>
              <Link to={'/newPost/' + topicID} style={{textDecoration: 'none'}}>
                <h1>
                    +
                </h1>
              </Link>
            </div>
          </td>
        </tr>
        <tr key={"nav"}>
          <td>
            {prevButton}
          </td>
          <td>
            <div className='tooltip' style={{float: 'right'}}>
             {nextButton}
            </div>
          </td>
        </tr>
      </tbody></table>
      {!threads ? getDummyThreads() : getThreadTable(threads, sessionData?.user)} 
      <br/>
      <table style={{width: '100%'}}><tbody>
        <tr key={"nav"}>
          <td>
            {prevButton}
          </td>
          <td>
            <div className='tooltip' style={{float: 'right'}}>
             {nextButton}
            </div>
          </td>
        </tr>
      </tbody></table>
    </div>
  );
}

/*
<table className="threadRow"><tbody>
              <tr key={row.ID} className="threadRow">
                <td key={row.ID + "a"} className="threadCell threadPinCell">{row['pinned'] === 1 ? "📌" : ""}</td>
                <td key={row.ID + "b"} className="threadCell threadTitleCell"><UserProfileDisplay comment={user}/></td>
                <td key={row.ID + "c"} className="threadCell threadDescCell"><b>{row['title']}</b></td>
                <td key={row.ID + "d"} className="threadCell threadUserCell"><span style={{"color":('#' + row['displayNameHex'])}}>{row['displayName']}</span></td>

              </tr>
              <tr key={row.ID + 1} className="threadRow">
                <td key={row.ID + "a2"} className="threadCell threadPinCell">{row['NumComments']} 💬 </td>
                <td key={row.ID + "b2"} className="threadCell threadTitleCell">{body}</td>
                <td key={row.ID + "c2"} className="threadCell threadDescCell">{months[act.getMonth()] + " " + act.getDate() + ", " + act.getFullYear()}</td>
                
                <td key={row.ID + "d2"} className="threadCell threadUserCell">{months[created.getMonth()] + " " + created.getDate() + ", " + created.getFullYear()}</td>

              </tr>
            </tbody></table>

            <table className="threadTable">
        <thead>
          <tr key={"head"}>
            <th key={"heada"} className="threadCell threadPinCell"></th>
            <th key={"headb"} className="threadCell threadTitleCell">Thread Title</th>
            <th key={"headc"} className="threadCell threadDescCell">Thread Description</th>
            
            <th key={"headd"}className="threadCell threadUsereCell">Creator</th>

          </tr>
        </thead>
      </table>
            */