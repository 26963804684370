import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useToken from '../Account/Components/useSession';
import Login from '../Account/Login';
import '../../App.css';
import '../../styles/newThread.css';
import {
  Navigate,
  useParams
} from "react-router-dom";
import ImageTools from '../Images/ImageTools';

export default function NewThread({ sessionData, setSessionData }) {
    let [title, setTitle] = useState();
    let [desc, setDesc] = useState();
    let [success, setSuccess] = useState();
    let [forbidden, setForbidden] = useState();
    let [unauth, setUnauth] = useState();
    let [tooLarge, setTooLarge] = useState();
    let [file, setFile] = useState();
    const { topicID } = useParams();

    let err = "No Error";

    const handleSubmit = async e => {
        e.preventDefault();
        if(title && desc) {
            const formData = new FormData();
            if(file) {
                formData.append('image', file);
                console.log("attached image");
            }
            formData.append('title', title);
            formData.append('desc', desc);
            formData.append('username', sessionData?.user?.username);
            formData.append('SID', sessionData?.token);
            formData.append('topic', topicID);
            console.log("Creating Thread" + title + desc);
            let url = "https://api.board.dylang140.com/newThread";
            let c = await fetch(url, {
                method: 'POST',
                body: formData
              });
            if(c.ok) {
                setSuccess(true);
            }
            else if(c.status === 403) { //Forbidden
                setForbidden(true);
            }
            else if(c.status === 401) { //Unauthorized 
                setUnauth(true);
            }
        }
    }

     let uploadFile = async (e) => {
        //console.log(e);
        if(e.size >= 1000000) {
          setTooLarge(true);
          console.log("resizing");
          let newFile = await ImageTools.resizeFile(e); //Resize image if too large
          //console.log(newFile);
          setFile(ImageTools.dataURItoBlob(newFile));
          setTooLarge(false);
        }
        else {
          console.log("all good");
          setFile(e);
        }
      };

    if(forbidden) {
        return <Navigate replace to="/forbidden" />
    }
    else if(unauth) {
        return <Navigate replace to="/logout" />
    }
    else if(success) {
        return <Navigate replace to="/" />  
    }
    else if(sessionData?.token){
        return(
            <div className="Page newThread">
            <h1>New Post</h1>
            <form onSubmit={handleSubmit}>
                <label>
                    <p>Post Title</p>
                    <input type="text" className="TextField" maxLength="35" onChange={e => setTitle(e.target.value)} value={title} />
                    <br/>{title ? title.length + "/35" : ""}
                </label>
                <label>
                    <p>Description</p>
                    <textarea type="text" className="TextField" maxLength="10000" onChange={e => setDesc(e.target.value)} value={desc} rows="10" cols="50"/>
                    <br/>{desc ? desc.length + "/10,000" : ""}
                </label>
                <br/>
                <img width="400" src={file ? URL.createObjectURL(file) : ""} />
                <br/>
                    <input type="file" name="file" onChange={e => uploadFile(e.target.files[0])}/>
                <br/>
                <div>
                    <br/>
                    <button className="Button" type="submit">Submit</button>
                </div>
            </form>
            </div>
        )
    }
    else {
        return <Login sessionData={sessionData} setSessionData={setSessionData} />;
    }
}

Login.propTypes = {
    setSessionData: PropTypes.func.isRequired,
};