import PermTools from '../Account/Utilities/PermTools';

export default function TopicManager({ sessionData, topicData }) {
    //let [locked, setLocked] = useState(topicData.locked);
    if(sessionData?.user && ((PermTools.userHasPerm(sessionData, 20) && sessionData.user.username == topicData.username) || PermTools.userHasPerm(sessionData, 21))) { //Has perm to manage own topic, #20, and is owner, or has perm to manage any topic, #21
        return (
            <div>
                <h3>You can manage this topic</h3>
                
            </div>
        );
    }
}
